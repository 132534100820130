html * {
  outline: 0 !important;
}

body {
  font-family: "bpg_mrgvlovani", sans-serif;
  background-color: #3f3124;
}

html,
body {
  height: 100%;
}

a,
a:hover {
  text-decoration: none;
}

.new-item {
  display: flex;
  padding: 10px 15px;
  border-left: 1px solid #a78c74;
  height: 20px;
  align-items: center;
  justify-content: center;
  
  img {
    margin-left: 8px;
  }
}

.page-wrap {
  min-height: 100%;
  /* equal to footer height */
  margin-bottom: -50px;
}

.page-wrap:after {
  content: "";
  display: block;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 60px;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.25);
  transition: transform 300ms;

  .material-icons {
    position: absolute;
    top: 18px;
    left: 28px;
    color: #fff;
  }
}

a:hover > .play-button,
div:hover > .play-button {
  transform: translate(-50%, -50%) scale(1.1);
}

#footer,
.page-wrap:after {
  height: 50px;
}

.navbar {
  border: none;
  margin-bottom: 0;
  z-index: 99;

  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.navbar-default {
  border-radius: 0;
  background-color: #473524;
  border-color: transparent;

  .navbar-toggle {
    &,
    .icon-bar {
      border-color: #a78c74;
    }

    &:focus,
    &:hover {
      background-color: #1E1610;
    }
  }

  .navbar-nav {
    .new-item {
      a {
        color: #a78c74;
        text-transform: uppercase;
        font-size: 12px;
        font-family: "bpg_mrgvlovani_caps", sans-serif;

        &:hover {
          color: #1f1712;
        }
      }
      
    }
    > li > a {
      color: #a78c74;
      text-transform: uppercase;
      font-size: 12px;
      font-family: "bpg_mrgvlovani_caps", sans-serif;

      -webkit-transition: color 200ms;
      transition: color 200ms;

      &:focus,
      &:hover {
        color: #1f1712;
      }
    }

    > .active > a,
    > .active > a:focus,
    > .active > a:hover {
      color: #1f1712;
      background-color: transparent;
    }
  }
}

.navbar-collapse {
  border-top: none;
}

.modal-header {

}

.modal-header {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #dfba0c;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: none;

  .close {
    margin-top: 2px;
  }
}

.modal-title {
  color: #292521;
  text-transform: uppercase;
}

.modal-content {
  border: none;
  background-color: #292521;
  border-radius: 4px;
}

.modal-body {
  padding: 30px;
}

.tab-content {
  padding-top: 20px;
  padding-bottom: 20px;
}

.nav-tabs {
  padding-top: 30px;
  text-align: center;
  border-bottom: none;
  background-color: #24201d;

  > li {
    float: none;
    display: inline-block;

    > a {
      border: none;
      border-bottom: 2px solid transparent;
      margin-left: 16px;
      margin-right: 16px;
      border-radius: 0;
      color: #a78c74;

      &:focus,
      &:hover {
        border-color: transparent;
        background-color: transparent;
      }
    }

    &.active > a,
    &.active > a:focus,
    &.active > a:hover {
      border: none;
      background-color: transparent;
      border-bottom: 2px solid #473524;
      color: #a78c74;
    }
  }
}

.row-frequent {
  margin-left: -3px;
  margin-right: -3px;

  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-xs-1,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9 {
    padding: 3px;
  }
}

.bxslider {
  img {
    width: 100%;
    display: none;
  }

  video {
    width: 100%;
  }
}

.bx-wrapper {
  border: none;
  background-color: transparent;

  -webkit-box-shadow: none;
  box-shadow: none;

  .bxslider img {
    display: block;
  }
}

.social,
.languages {
  position: absolute;

  ul {
    margin-bottom: 0;
  }

  li {
    margin-left: 4px;
    margin-right: 4px;
    display: inline-block;
  }
}

.social {
  top: 20px;
  right: 20px;

  li {
    margin-left: 0;
    margin-right: 0;

    a {
      color: #fff;

      &.azm-facebook,
      &.azm-instagram,
      &.azm-twitter {
        background-color: transparent;
        -webkit-transition: background-color 200ms;
        transition: background-color 200ms;
      }

      &.azm-facebook:hover {
        background-color: #4862a3;
      }

      &.azm-instagram:hover {
        background-color: #3d6b92;
      }

      &.azm-twitter:hover {
        background-color: #55acee;
      }
    }
  }
}

.languages {
  top: 125px;
  right: 50px;

  li {
    a {
      color: #584534;
    }

    &.active a {
      color: #a78c74;;
    }
  }
}

.jssocials-share-logo {
  color: #fff !important;
}

#scrollToTop {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 48px;
  height: 48px;
  border: none;
  background-color: #ccc;
  border-radius: 50%;
  z-index: 99;

  -webkit-box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.2);

  .material-icons {
    vertical-align: middle;
  }
}

#header {
  width: 100%;
  min-height: 212px;
  background-color: rgba(30, 22, 16, 0.9);

  &.absolute-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }

  .logo {
    height: 162px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 110px;
      left: 50%;
      width: 190px;
      border-top: 1px solid #4d3b2b;
    }

    &:before {
      transform: translateX(-150%);
    }

    &:after {
      transform: translateX(50%);
    }
  }
}

#footer {
  padding-top: 15px;
  padding-bottom: 15px;

  p {
    color: #a78c74;
    font-size: 14px;
    margin-bottom: 0;

    span {
      color: #584534;
    }
  }
}

#map {
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
}

#contact {
  p {
    color: #a78c74;
    font-size: 12px;
  }

  h5 {
    color: #fff;
  }

  textarea {
    resize: none;
  }

  .btn {
    color: #a78c74;
    padding-left: 24px;
    padding-right: 24px;
    border-color: #40352b;
    background-color: #40352b;
    border-radius: 2px;
  }

  .form-control {
    color: #888;
    border-color: #45372a;
    background-color: #2e2923;
    border-radius: 0;

    -webkit-box-shadow: none;
    box-shadow: none;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #746355;
    }

    &::-moz-placeholder { /* Firefox 19+ */
      color: #746355;
    }

    &:-ms-input-placeholder { /* IE 10+ */
      color: #746355;
    }
  }
}

#intro {
  position: relative;

  .bg-image {
    min-height: 750px;
    background: no-repeat center top;
    background-size: cover;
  }

  .bubble {
    width: 360px;
    height: 360px;
    position: absolute;
    top: 52%;
    left: 50%;
    display: table;
    padding: 40px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    text-align: center;

    -webkit-transform: translate(-50%, -25%);
    transform: translate(-50%, -25%);

    -webkit-transition-property: top, left, width, height, background-color, border-radius;
    transition-property: top, left, width, height, background-color, border-radius;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;

    &.bubble-video {
      top: 60%;
      width: 120px;
      height: 120px;
      cursor: pointer;

      //&:hover {
      //  width: 132px;
      //  height: 132px;
      //  top: calc(60% - 3px);
      //  background-color: rgba(0, 0, 0, 0.35);
      //}

      .content p,
      .content h4 {
        display: none;
      }

      .content i {
        display: block;
      }

      &.play {
        top: 50%;
        padding: 10px;
        border-radius: 10px;
        background-color: transparent;

        .content i {
          display: none;
        }

        .content video {
          opacity: 1;
          visibility: visible;
          -webkit-transform: translate(-50%, -35%);
          transform: translate(-50%, -35%);
          border: 4px solid #473524;
          border-radius: 4px;
        }
      }
    }

    h4 {
      color: #fff;
      margin-top: -20px;
      margin-bottom: 40px;
      text-transform: uppercase;
    }

    p {
      color: #f3bf8f;
      font-size: 11px;
      line-height: 1.5;
    }

    .content {
      display: table-cell;
      vertical-align: middle;

      i {
        display: none;
        font-size: 28px;
        color: rgba(255, 255, 255, 0.5);
      }

      video {
        width: 700px;
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 0;
        visibility: hidden;

        -webkit-transform: translate(-50%, -60%);
        transform: translate(-50%, -60%);

        -webkit-transition: opacity 500ms, visibility 500ms, -webkit-transform 1s;
        transition: opacity 500ms, visibility 500ms, transform 1s;
      }
    }
  }

  .bx-wrapper {
    margin-bottom: 0;

    .bx-controls-direction a {
      -webkit-transform: translateY(106px);
      transform: translateY(106px);
    }
  }
}

#home-gallery {
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 50px;

  .container-fluid {
    padding-left: 3px;
    padding-right: 3px;
  }

  .details {
    width: 50%;
    height: 100%;
    position: relative;
    padding: 20px;
    overflow: hidden;

    &:hover p {
      opacity: 1;
      visibility: visible;
    }

    p {
      color: #f3bf8f;
      font-size: 12px;
      max-height: 120px;
      opacity: 0;
      visibility: hidden;

      -webkit-transition-property: opacity, visibility;
      transition-property: opacity, visibility;

      -webkit-transition-duration: 300ms;
      transition-duration: 300ms;
    }

    h3 {
      color: #fff;
      text-transform: uppercase;
    }
  }

  .gallery-image-box {
    height: 300px;
    position: relative;
    //overflow: hidden;

    a {
      height: 100%;
      display: block;
    }

    &.gallery-image-box-news .details {
      background-color: rgba(42, 42, 42, 0.3);
    }

    &.gallery-image-box-special-packages .details {
      padding-top: 54px;
      background-color: rgba(40, 31, 23, 0.3);

      .scrollable {
        max-height: 167px;
      }

      .band {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        text-transform: uppercase;
        padding: 6px 24px;
        font-size: 16px;
        background-color: #dfba0c;
        color: #333;

        &:before,
        &:after {
          content: '';
          position: absolute;
          right: -17px;
          border-style: solid;
        }

        &:before {
          top: 0;
          border-width: 17px 17px 0 0;
          border-color: #dfba0c transparent transparent transparent;
        }

        &:after {
          bottom: 0;
          border-width: 17px 0 0 17px;
          border-color: transparent transparent transparent #dfba0c;
        }
      }

      .price {
        margin-top: 15px;
        margin-bottom: 15px;

        .sale {
          color: #dfba0c;
          font-size: 24px;
        }

        .default {
          color: #825f41;
          font-size: 18px;
          margin-left: 20px;
        }
      }

      .list-group-item {
        color: #c5c5c5;
        border-color: #c6c6c6;
        padding-left: 0;
        padding-right: 0;
        background-color: transparent;
        border-left: none;
        border-right: none;

        &:first-child {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

        &:last-child {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  .bg-image {
    width: 100%;
    background: no-repeat center center;
    background-size: cover;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    //-webkit-transition: -webkit-transform 0.8s;
    //transition: transform 0.8s;

    //&:hover {
    //  -webkit-transform: scale(1.1) rotate(1deg);
    //  transform: scale(1.1) rotate(1deg);
    //
    //  &:before {
    //    background-color: rgba(0, 0, 0, 0.2);
    //  }
    //}

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);

      -webkit-transition: background-color 0.8s;
      transition: background-color 0.8s;
    }
  }
}

.bg-color {
  background-color: #292521;
}

.paragraph {
  text-align: center;
  background-color: #292521;

  .title {
    color: #fff;
    margin-top: 100px;
    margin-bottom: 60px;
    font-family: "bpg_mrgvlovani_caps", sans-serif;
  }

  .description {
    color: #a78c74;
  }

  .separator {
    max-width: 400px;
    border-color: #4d3b2b;
    margin-top: 40px;
    margin-bottom: 80px;
  }
}

.filter-bar {
  text-align: center;
  background-color: #24201d;
  margin-top: -15px;
  margin-bottom: 15px;
  padding-top: 15px;

  button,
  .form-control {
    margin: 5px 2px 20px;
    border-radius: 2px;
  }

  button {
    padding-left: 36px;
    padding-right: 36px;
    color: #fff !important;
    background-color: #7d6958;
    border-color: #7d6958;

    -webkit-transition-property: background-color, border-color;
    transition-property: background-color, border-color;

    -webkit-transition-duration: 200ms;
    transition-duration: 200ms;

    &:focus,
    &:hover {
      background-color: darken(#7d6958, 2%);
      border-color: darken(#7d6958, 2%);
    }
  }

  select.form-control {
    color: #a78c74;
    min-width: 120px;
    border-color: transparent;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

#home-gallery,
#footer,
#contact,
.services,
.membership,
.about-us,
.prices,
.news {
  background-color: #292521;
}

#contact,
.services,
.membership,
.about-us,
.prices,
.news {
  margin-bottom: 40px;
}

#contact,
.membership {
  padding-top: 40px;
  padding-bottom: 40px;
}

.about-us {
  .media {
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #24201d;
  }

  .media-left,
  .media > .pull-left {
    padding-right: 0;
  }

  .media-heading {
    margin-bottom: 10px;
  }

  .media-body {
    padding: 20px;
  }
}

.services {
  padding-top: 20px;
  padding-bottom: 20px;

  .btn-price {
    color: #333;
    font-size: 20px;
    padding-left: 48px;
    padding-right: 48px;
    border-radius: 2px;
    border-color: #dfba0c;
    background-color: #dfba0c;

    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  }

  .row-frequent {
    .col-md-2,
    .col-sm-3,
    .col-xs-12 {
      padding-top: 0;
      padding-bottom: 0;
    }

    .thumbnail {
      margin-top: 3px;
      margin-bottom: 3px;
    }
  }

  .thumbnail {
    padding: 0;
    border: none;
    border-radius: 0;
    text-align: center;
    margin-bottom: 0;
    background-color: transparent;
    position: relative;
    overflow: hidden;

    &.thumbnail-overlay:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
    }

    > img {
      width: 100%;

      //-webkit-transition: -webkit-transform 0.8s;
      //transition: transform 0.8s;
    }

    //&:hover > img {
    //  -webkit-transform: scale(1.1) rotate(1deg);
    //  transform: scale(1.1) rotate(1deg);
    //}

    .btn-price,
    &.thumbnail-overlay .caption {
      position: absolute;
    }

    .btn-price {
      bottom: 20px;
      left: 50%;

      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);

      &.btn-price-top {
        top: 20px;
        bottom: auto;
      }
    }

    &.thumbnail-overlay .caption {
      top: 50%;
      left: 50%;

      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);

      h5 {
        color: #fff;
      }
    }

    .prices-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      padding: 20px;

      > div:not(:first-child) {
        margin-top: 5px;
        padding-top: 5px;
        border-top: 1px solid rgba(71, 53, 36, 0.5);
      }

      .price,
      .title,
      .hypen {
        color: white;
      }

      .hypen {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
}

.news,
.prices {
  padding-top: 17px;
  padding-bottom: 17px;

  .item-content,
  .item-content .details {
    height: 100%;
  }

  .item-content {
    position: relative;

    .bg-image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: no-repeat center top;
      background-size: cover;
    }

    .details {
      width: 50%;
      position: relative;
      padding: 10px 20px 20px;
      //padding: 54px 20px 20px;

      p {
        color: #f3bf8f;
        font-size: 12px;
        max-height: 100px;
      }

      h3 {
        color: #fff;
        text-transform: uppercase;
      }
    }
  }
}

.news .item-content .details {
  min-height: 300px;
  padding-top: 14px;
  background-color: rgba(42, 42, 42, 0.3);
  position: relative;

  &:hover p {
    opacity: 1;
    visibility: visible;
  }

  p {
    opacity: 0;
    visibility: hidden;

    -webkit-transition-property: opacity, visibility;
    transition-property: opacity, visibility;

    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
  }

  .release-date {
    color: #fff;
    font-size: 12px;
    position: absolute;
    left: 20px;
    bottom: 0;
  }
}

.prices .item-content .details {
  background-color: rgba(40, 31, 23, 0.4);

  .scrollable {
    max-height: 167px;
  }

  .band {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    text-transform: uppercase;
    padding: 6px 24px;
    font-size: 16px;
    background-color: #dfba0c;
    color: #333;

    &:before,
    &:after {
      content: '';
      position: absolute;
      right: -17px;
      border-style: solid;
    }

    &:before {
      top: 0;
      border-width: 17px 17px 0 0;
      border-color: #dfba0c transparent transparent transparent;
    }

    &:after {
      bottom: 0;
      border-width: 17px 0 0 17px;
      border-color: transparent transparent transparent #dfba0c;
    }
  }

  .price {
    margin-top: 15px;
    margin-bottom: 15px;

    .sale {
      color: #dfba0c;
      font-size: 24px;
    }

    .default {
      color: #825f41;
      font-size: 18px;
      margin-left: 20px;
    }
  }

  .list-group-item {
    color: #c5c5c5;
    border-color: #c6c6c6;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    border-left: none;
    border-right: none;

    &:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &:last-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.membership {
  a[data-toggle="modal"] {
    text-decoration: none;
  }

  .panel,
  .panel-heading {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .panel {
    border: none;
    margin-bottom: 0;
    background-color: #4c423a;
  }

  .panel-title {
    color: #292521;
  }

  .panel-heading {
    background-color: #dfba0c;
  }
}

.membership,
.modal-body {
  .price {
    color: #dfba0c;
  }

  .description {
    padding-left: 20px;

    > li {
      color: #a78c74;
      font-size: 12px;
    }
  }

  .list-group-item {
    color: #fff;
    border-color: #453c34;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    border-left: none;
    border-right: none;

    &:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &:last-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .fa {
    color: #666;
    font-size: 16px;
  }

  hr {
    border-color: #423a32;
  }
}

.modal-custom {
  img {
    width: 100%;
  }

  .close {
    color: #666;
    text-shadow: none;
    float: none;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 99;
  }

  .content {
    p {
      color: #a78c74;
      line-height: 2;
    }

    h3 {
      color: #fff;
      text-transform: uppercase;
      margin-top: 5px;
      margin-bottom: 30px;
    }
  }

  .scrollable {
    display: none;
    max-height: 360px;

    &.sm {
      max-height: 300px;
    }
  }

  .service-price {
    margin-bottom: 20px;
    border-bottom: 1px solid #4d3b2b;

    .sale {
      color: #dfba0c;
      font-size: 24px;
    }

    .default {
      color: #825f41;
      font-size: 18px;
      margin-left: 20px;
    }
  }

  .modal-dialog {
    max-width: 1170px;
  }

  .modal-content {
    border-radius: 0;
  }

  .modal-body {
    padding: 15px;
  }

  .mCSB_inside > .mCSB_container {
    margin-right: 15px;
  }

  .bx-wrapper {
    margin-bottom: 10px;
  }

  .list-group {
    margin-top: 20px;
  }

  .prices-wrapper {
    hr {
      background-color: #423a32;
    }

    > div {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }

    .title {
      color: white;
    }

    .hypen {
      color: white;
      margin-left: 5px;
      margin-right: 5px;
    }

    .sale,
    .price {
      color: yellow;
    }

    .default {
      color: #825f41;
      font-size: 12px;
    }
  }
}

.pagination > li > a,
.pagination > li > span {
  border: none;
  background-color: transparent;
  color: #584534;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover,
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  border-color: transparent;
  background-color: transparent;
  color: #825f41;
}

// Common

@media only screen and (max-width: 1199px) {
  #intro .bg-image {
    min-height: 700px;
  }
}

@media only screen and (max-width: 767px) {
  #header .logo {
    &:before,
    &:after {
      width: 95px;
    }

    &:before {
      transform: translateX(-170%);
    }

    &:after {
      transform: translateX(70%);
    }
  }

  #intro {
    .bg-image {
      min-height: 550px;
    }

    .bubble {
      top: 55%;
      width: 260px;
      height: 260px;

      h4 {
        font-size: 14px;
        margin-top: 0;
        margin-bottom: 20px;
      }

      p {
        font-size: 10px;
      }
    }
  }

  .social {
    top: 165px;
    left: 5px;
    right: auto;
    z-index: 100;
  }

  .languages {
    top: 20px;
    right: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .is-table-row {
    display: table;
  }

  .is-table-row [class*="col-"] {
    float: none;
    display: table-cell;
    vertical-align: top;
  }

  .modal-dialog {
    margin-top: 240px;
  }
}

// Navbar

@media only screen and (min-width: 768px) {
  .navbar {
    border-radius: 0;
  }

  .navbar-nav {
    width: 100%;
    float: none;
    display: table;
    table-layout: auto;
    margin: 0 auto;

    > li {
      float: none;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
    }
  }

  .navbar-default .navbar-nav {
    > li > a:before,
    > li:last-child > a:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      height: 20px;

      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    > li > a:before {
      left: 0;
      border-left: 1px solid #a78c74;
    }

    > li:last-child > a:after {
      right: 0;
      border-right: 1px solid #a78c74;
    }
  }
}

@media only screen and (min-width: 992px) {
  .navbar-default .navbar-nav > li > a {
    font-size: 14px;
  }
}

// Home Gallery

@media only screen and (max-width: 1199px) {
  #home-gallery .gallery-image-box {
    height: 260px;
  }
}

@media only screen and (max-width: 991px) {
  #home-gallery .gallery-image-box {
    height: 220px;
  }
}

@media only screen and (max-width: 767px) {
  #home-gallery .details {
    width: 66%;
  }

  #intro .bubble.bubble-video.play .content video {
    width: 320px;
    -webkit-transform: translate(-50%, -18%);
    transform: translate(-50%, -18%);
  }
}

// About us
@media only screen and (max-width: 767px) {
  .about-us {
    padding-bottom: 20px;

    .media-body,
    .media-left,
    .media-right {
      display: block;
    }

    .media-body,
    .media-object {
      width: 100%;
    }
  }

  .paragraph .title {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .news,
  .prices {
    .item-content .details {
      width: 66%;
    }
  }

  .prices .item-content .details .band {
    font-size: 10px;
  }
}

// Prices
@media only screen and (max-width: 767px) {
  .prices .item-content .details {
    h3,
    .price .sale {
      font-size: 18px;
    }

    .list-group-item {
      font-size: 12px;
    }

    .price .default {
      font-size: 12px;
    }
  }
}

// Modal
@media only screen and (min-width: 768px) {
  .modal-dialog {
    width: 70%;
  }
}

@import "icons";

